import React from 'react';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';

import { DATES_SELECTED } from 'constants/amplitude';

import { useDatesPopoverContext } from 'contexts/datesPopover';

import { convertDateToISOApiString } from 'utils/dates';

import AppButton from 'components/AppButton/AppButton';

import styles from './styles/AppSearchWithDates.module.scss';

const HOST = process.env.NEXT_PUBLIC_NEXT_HOST;

const cx = classNames.bind(styles);

interface Props {
  targetSearchUrl: string;
  routerMethod: 'push' | 'replace';
  handleSearchClick?: (dates: [Date | null, Date | null]) => void;
}

const DatesPopoverRightButton: React.FC<Props> = ({
  targetSearchUrl,
  routerMethod,
  handleSearchClick,
}) => {
  const { dates, close } = useDatesPopoverContext();

  const router = useRouter();

  const firstDateChosen = dates[0];

  const buttonClasses = cx({
    'date-select__button-skip': !firstDateChosen,
  });

  const handleSearchBtnClick = () => {
    close.current && close.current();

    const url = new URL(targetSearchUrl || `${HOST}/search`);

    const searchParams = url.searchParams;

    const filtersString = searchParams.get('filters');

    const filters: AppliedSearchFilter = filtersString
      ? JSON.parse(filtersString)
      : {};

    if (dates[0] && dates[1]) {
      filters['start_date'] = convertDateToISOApiString(dates[0]);
      filters['end_date'] = convertDateToISOApiString(dates[1]);
      filters.available = '1';

      amplitude.track(DATES_SELECTED, {
        'check in date': `${convertDateToISOApiString(dates[0])}`,
        'check out date': `${convertDateToISOApiString(dates[1])}`,
      });
    } else {
      delete filters['start_date'];
      delete filters['end_date'];
      delete filters['available'];
    }

    searchParams.set('filters', JSON.stringify(filters));

    if (handleSearchClick) handleSearchClick(dates);

    router[routerMethod](url.toString());
  };

  return (
    <AppButton
      label={!firstDateChosen ? 'Skip' : 'Search'}
      type="button"
      onClick={handleSearchBtnClick}
      dataEvent="date-range-search"
      className={buttonClasses}
    />
  );
};

export default DatesPopoverRightButton;
