import React, { MutableRefObject, useMemo } from 'react';

import { DatesPopoverContextProvider } from 'contexts/datesPopover';

import AppDatesSelect from 'components/AppDatesSelect/AppDatesSelect';
import AppSearch from 'components/AppSearch/AppSearch';

import DatesPopoverRightButton from './DatesPopoverRightButton';

import styles from './styles/AppSearchWithDates.module.scss';

interface Props {
  targetSearchUrl: string;
  onResultSelected?: (
    result: UnifiedAutocomplete | null,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  routerMethod?: 'push' | 'replace';
  startDate: null | Date;
  endDate: null | Date;
  yieldOpen?: MutableRefObject<() => void>;
  baseSearchUrl?: string;
  sendEventOnSearchWithDates?: (dates: [Date | null, Date | null]) => void;
}

const AppSearchWithDates: React.FC<Props> = ({
  targetSearchUrl,
  onResultSelected,
  routerMethod = 'push',
  startDate,
  endDate,
  yieldOpen,
  sendEventOnSearchWithDates,
}) => {
  const rightButton = useMemo(() => {
    return (
      <DatesPopoverRightButton
        targetSearchUrl={targetSearchUrl}
        routerMethod={routerMethod}
        handleSearchClick={sendEventOnSearchWithDates}
      />
    );
  }, [targetSearchUrl, routerMethod, sendEventOnSearchWithDates]);

  return (
    <div className={styles['container']}>
      <AppSearch
        className={styles['app-search']}
        resultsClassName={styles['app-search__results']}
        onResultSelected={onResultSelected}
        hideClear
      />
      <div className={styles['divider']} />
      <DatesPopoverContextProvider startDate={startDate} endDate={endDate}>
        <AppDatesSelect
          rightButton={rightButton}
          className={styles['date-select']}
          yieldOpen={yieldOpen}
        />
      </DatesPopoverContextProvider>
    </div>
  );
};

export default AppSearchWithDates;
