import React, { MutableRefObject, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import debounce from 'lodash.debounce';

import { useDatesPopoverContext } from 'contexts/datesPopover';

import { convertDateToISODisplayString } from 'utils/dates';
import { isServer } from 'utils/isServer';

import { useModalOrPopover } from 'hooks/useModalOrPopover';
import useOnClickOutsideRef from 'hooks/useOnClickOutsideRef';
import { useOverlayContext } from 'hooks/useOverlayContext';

import AppDatesSelectContent from 'components/AppDatesSelect/AppDatesSelectContent';
import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppDatesSelect.module.scss';

const cx = classNames.bind(styles);
export interface AppDatesSelectProps {
  className?: string;
  focusedClassName?: string;
  rightButton: JSX.Element;
  yieldOpen?: MutableRefObject<() => void>;
}

export const AppDatesSelect: React.FC<AppDatesSelectProps> = React.memo(
  ({ className, focusedClassName, rightButton, yieldOpen }) => {
    const [open, setOpen] = useState<boolean>(false);

    const { setOverlay } = useOverlayContext<DateRangePickerOverlay>();

    const shouldUseModal = useModalOrPopover();

    const datesPopoverContext = useDatesPopoverContext();

    const {
      dates: [startDate, endDate],
      revert,
    } = datesPopoverContext;

    const popoverRef = useOnClickOutsideRef(() => {
      if (!shouldUseModal() && open) {
        revert();
        setOpen(false);
      }
    });

    const hasAppliedDates = !!(startDate && endDate);

    const openDatePicker = () => {
      if (shouldUseModal()) {
        setOverlay({
          type: 'date-range-picker',
          rightButton,
          context: datesPopoverContext,
        });
      } else {
        setOpen(true);
      }
    };

    if (yieldOpen) {
      yieldOpen.current = openDatePicker;
    }

    useEffect(() => {
      if (isServer) {
        return;
      }

      const onResize = debounce(() => {
        shouldUseModal() && setOpen(false);
      }, 500);

      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, [shouldUseModal]);

    const iconClassNames = cx({
      toggle__icon: true,
      'toggle__icon--focused': open,
    });

    return (
      <div
        className={`${className} ${open ? focusedClassName : ''}`}
        ref={popoverRef}
      >
        <button
          type="button"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            openDatePicker();
          }}
          className={`${styles['toggle']}`}
        >
          <AppIcon icon={'calendar'} classNameWrapper={iconClassNames} />
          <label
            className={`${styles['toggle__label']} ${
              hasAppliedDates ? '' : styles['toggle__label--placeholder']
            }`}
          >
            {hasAppliedDates
              ? `${convertDateToISODisplayString(
                  startDate
                )} - ${convertDateToISODisplayString(endDate)}`
              : 'Add dates'}
          </label>
        </button>
        {open && (
          <AppDatesSelectContent
            className={styles['popover']}
            close={() => setOpen(false)}
            rightButton={rightButton}
          />
        )}
      </div>
    );
  }
);

AppDatesSelect.displayName = 'AppDatesSelect';

export default AppDatesSelect;
